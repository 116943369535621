import { mapState, mapActions } from 'vuex';

import { useDataLoading, usePagination } from '@mixins/factories';

const dataLoadingOptions = { getterName: 'getSettingsHistory', searchable: false };
const paginationOptions = { itemsName: 'settingsHistory' };

const tabs = Object.freeze([
  { text: 'Settings', key: 'settings' },
  { text: 'History', key: 'history' },
]);

const headers = Object.freeze([
  { text: 'Operator', value: 'login' },
  { text: 'Changed Setting', value: 'changedSetting' },
  { text: 'Processed Date', value: 'processed' },
  { text: 'Previous Value', value: 'previousValue' },
  { text: 'New Value', value: 'newValue' },
]);

export default {
  name: 'AdvancedSettings',
  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
  ],
  data: () => ({
    tabs,
    headers,
    tab: 'settings',
    modal: { show: false, title: '', code: '' },
  }),
  computed: {
    ...mapState('advancedSettings', [
      'settings',
      'settingsHistory',
      'settingsHistoryTotalCount',
    ]),
    isInvalidForm() {
      return !this.settings.length || this.settings.some(({ name, value }) => !name || !value);
    },
  },
  created() {
    this.getSettings();
  },
  methods: {
    ...mapActions('advancedSettings', ['getSettings', 'getSettingsHistory', 'saveSettings']),
    updateSettings() {
      if (this.isInvalidForm) {
        return;
      }

      this.$VBlackerTheme.alert.warning({
        text: 'Update advanced settings?',
      }, async () => {
        this.$VBlackerTheme.openLoadingSpinner();
        try {
          await this.saveSettings();
          this.pageNum = 1;
          this.allowGettingData = true;
          this.$VBlackerTheme.notification.success('Settings successfully updated');
        } catch (e) {
          this.$VBlackerTheme.notification.error(e);
        } finally {
          this.$VBlackerTheme.closeLoadingSpinner();
        }
      });
    },
    addParameters() {
      this.settings.push({ name: '', value: '' });
    },
    removeParameters(index) {
      this.settings.splice(index, 1);
    },
    resetChanges() {
      this.getSettings();
    },
    openModal(type, code) {
      Object.assign(this, {
        modal: {
          title: `${type} value`,
          show: true,
          code,
        },
      });
    },
  },
};
